<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Planeaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Planeaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-planeacion-export"
                        v-if="$store.getters.can('hidrocarburos.planeaciones.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.planeaciones.create'
                          )
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="planeaciones"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        Fecha inicial<input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_inicial"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_final"
                          @input="getIndex()"
                        />
                      </th>
                      <th>Observaciones</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="planeacion in planeaciones.data"
                      :key="planeacion.id"
                    >
                      <td>{{ planeacion.id }}</td>
                      <td>{{ planeacion.fecha_inicial }}</td>
                      <td>{{ planeacion.fecha_final }}</td>
                      <td>{{ planeacion.observaciones }}</td>
                      <td>
                        <span
                          class="badge"
                          v-bind:class="[
                            planeacion.estado == 1
                              ? 'badge-dark'
                              : 'badge-success',
                          ]"
                        >
                          {{
                            planeacion.estado == 1 ? "BORRADOR" : "DEFINITIVO"
                          }}
                        </span>
                      </td>

                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.planeaciones.show'
                              )
                            "
                            @click="edit(planeacion)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.planeaciones.delete'
                              )
                            "
                            @click="destroy(planeacion.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="planeaciones.total">
                  <p>
                    Mostrando del <b>{{ planeaciones.from }}</b> al
                    <b>{{ planeaciones.to }}</b> de un total:
                    <b>{{ planeaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="planeaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <PlaneacionExport ref="PlaneacionExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import PlaneacionExport from "./PlaneacionExport";

export default {
  name: "Planeaciones",
  components: {
    pagination,
    Loading,
    PlaneacionExport,
  },
  data() {
    return {
      cargando: false,
      file: "",
      filtros: {
        fecha_inicial: null,
        fecha_final: null,
        estado: null,
      },
      planeaciones: {},
      listasForms: {
        estados: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/planeaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.planeaciones = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      this.cargando = true;
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
        this.cargando = false;
      });
    },

    destroy(idPlaneacion) {
      this.$swal({
        title: "Esta seguro de eliminar esta Planeacion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/planeaciones/" + idPlaneacion)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino la Planeacion exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/PlaneacionForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(planeacion) {
      return this.$router.push({
        name: "/Hidrocarburos/PlaneacionForm",
        params: { accion: "Editar", data_edit: planeacion, id: planeacion.id },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  beforeMount() {
    this.getIndex();
    this.getEstados();
    this.getMsg();
  },
};
</script>