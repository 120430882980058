<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-planeacion-export"
      style="background-color: #00000082"
    >
    <!-- //algo -->
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Planeacion</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a  
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="fecha_inicial" class="col-md-5"
                      >Fecha Inicial</label
                    >
                    <input
                      type="date"
                      id="fecha_inicial"
                      v-model="filtros.fecha_inicial"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="fecha_final" class="col-md-5"
                      >Fecha Final</label
                    >
                    <input
                      type="date"
                      id="fecha_final"
                      v-model="filtros.fecha_final"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="estado" class="col-md-5">Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_inicial1"
                              v-model="form.fecha_inicial"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_inicial1"
                              >Fecha Inicial</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_final1"
                              v-model="form.fecha_final"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_final1"
                              >Fecha Final</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_final_oferta"
                              v-model="form.fecha_final_oferta"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_final_oferta"
                              >Fecha Final de Oferta</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="observaciones"
                              v-model="form.observaciones"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="observaciones"
                              >Observaciones</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="planeaciones_volumenes"
                              v-model="form.planeaciones_volumenes"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="planeaciones_volumenes"
                              >Detallado Planeaciones</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('hidrocarburos.planeaciones.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "PlaneacionExport", //llegada tambien
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      form: {
        fecha_inicial: true,
        fecha_final: true,
        estado: true,
        fecha_final_oferta: true,
        observaciones: true,
        planeaciones_volumenes: true,
      },
      filtros: {
        fecha_inicial: null,
        fecha_final: null,
        estado: null,
      },
      listasForms: {
        estados: [],
      },
    };
  },

  methods: {
    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/planeaciones/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
